.heading {
    text-align: center;
  }
  
  .heading h1 {
    border: 1px solid black;
    display: inline-block;
    color: rgba(255, 255, 255, 0.85);
  }
  
  .heading h3 {
    border: 1px solid black;
    display: inline-block;
    color: rgba(255, 255, 255, 0.85);
  }
  
  .heading h4 {
    border: 1px solid black;
    display: inline-block;
    color: rgba(255, 255, 255, 0.85);
  }
  
  .heading h5 {
    border: 1px solid black;
    display: inline-block;
    color: rgba(255, 255, 255, 0.85);
  }
  