.background {
  margin: auto;
  background-color: #1b1b1bc0 !important;
}

.rows {
  text-align: left;
  color: black;
}

.question-type {
  background: rgba(217, 217, 217);
  width: 400px;
}

.box {
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.title {
  font-size: 25px;
}

.question {
  display: inline-block;
  font-size: 20px;
}

.answer {
  font-size: 15px;
}

.icon {
  left: 467px;
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

.arrow {
  left: 467px;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.right-arrow {
  transform: rotate(270deg);
}

.note {
  font-size: 15px;
}

.faq-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

