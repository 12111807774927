.open-background-box {
    /* margin-top: 100px;
    margin-bottom: 50px; */
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    background-color: rgba(27, 27, 27, 0.9);
    border-radius: 20px;

    margin-top: 25px;
    /* padding-bottom: 10px; */
}

.decrypt-group {
    display: flex;
}

.pass-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 14px;
}

.test {
    position: absolute;
    top: 20%;
    right: 1%;
}

.test:hover {
    color: #535050;
    cursor: pointer;
}

@media (max-width: 990px) {
    .open-background-box {
        width: 60%;
    }
}

@media (max-width: 480px) {
    .decrypt-group {
        display: block;
    }
}