.pass-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 14px;
}

.test {
    position: absolute;
    top: 20%;
    right: 1%;
}

.test:hover {
    color: #535050;
    cursor: pointer;
}