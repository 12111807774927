.File-background-box {
  /* margin-top: 100px;
    margin-bottom: 50px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255);
  border-radius: 20px;
  /* padding-bottom: 10px; */
}

.title {
  color: black;
  font-weight: bold;
}

.title-box {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.table-header {
  color: #424242;
}

.table-data {
  text-align: start;
  vertical-align: middle;
}

.filecount {
  /* text-align: start; */
  font-size: small;
  color: #000000;
  /* vertical-align: middle; */
  /* padding-left: 0px; */
  /* margin-left: 5px; */
  /* margin-bottom: 0px; */
}

.logo {
  width: 20% !important;
}

.selected-box {
  background-color: #424242;
  color: white;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.button-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
