.outside-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    flex: none;
    width: fit-content;
    height: fit-content;
    order: 0;
    flex-grow: 1;

}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    background: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.header-img {
    box-sizing: border-box;

    width: 688px;
    height: 150px;
    box-shadow: inset 0px 0px 15px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    object-fit: cover;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: 0;
}

.hr {
    width: 688px;
    height: 0px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    opacity: unset;
    display: flex;
    margin: 0 0 0 0;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
}
